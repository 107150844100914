<template>
  <div class="pa-3">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        max-width="700px"
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="6" md="10">
                  <v-autocomplete
                    v-model="editedItem.tier_type_id"
                    item-text="tier_type"
                    item-value="id"
                    :items="tier_type_list"
                    label="Tier Type"
                    :readonly="readonly"
                    :rules="[(v) => !!v || 'Tier Type obligatoire']"
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                    autocomplete="off"
                    class="inputPrice"
                    type="number"
                    dense
                    v-model.number="editedItem.prix"
                    :readonly="readonly"
                    label="Prix"
                    :rules="[(v) => !!v || 'Prix obligatoire']"
                    required
                    hide-spin-buttons
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container fill-height>
              <v-layout row justify-center align-center v-if="progress">
                <v-progress-circular
                  indeterminate
                  :size="30"
                  :width="7"
                  color="purple"
                ></v-progress-circular>
              </v-layout>
            </v-container>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="save" v-if="!readonly">
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click.stop="close">
              Fermer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>

    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_TIER_TYPE from "../graphql/Product/CREATE_TIER_TYPE.gql";
import UPDATE_TIER_TYPE from "../graphql/Product/UPDATE_TIER_TYPE.gql";

export default {
  components: {},
  name: "tiertypeprixform",
  props: {
    item: Object,
    items: Array,
    readonly: Boolean,
    showForm: Boolean,
    tiers_types: Array,
  },
  data: () => ({
    progress: false,
    valid: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    defaultItem: {},
    editedItem: {},
    combovalue: "",
  }),

  computed: {
    tier_type_list() {
      if (this.tiers_types) {
        let t;
        t = this.tiers_types;

        let yFilter = this.items
          ? this.items.map((itemY) => {
              return itemY.tier_type_id;
            })
          : [];
        yFilter = yFilter.filter((elm) => elm != this.item.tier_type_id);
        let filteredX = t.filter((itemX) => !yFilter.includes(itemX.id));
        return filteredX;
      } else return [];
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Ligne  "
          : "Ligne N° ".concat(parseInt(this.item.index) + 1);
      else return "";
    },
  },
  watch: {},

  created() {},
  mounted() {
    this.$refs.form.resetValidation();
    if (this.item) {
      if (this.item.id > 0) this.editedItem = Object.assign({}, this.item);
      else this.editedItem = Object.assign({}, this.defaultItem);
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      if (this.$refs.form.validate()) {
        this.progress = true;
        let tier_type = this.tier_type_list.find(
          (elm) => elm.id == this.editedItem.tier_type_id
        ).tier_type;

        if (this.item.id > 0) {
          this.$apollo
            .mutate({
              mutation: UPDATE_TIER_TYPE,
              variables: {
                tiertypeprix: {
                  id: this.editedItem.id,
                  tier_type_id: this.editedItem.tier_type_id,
                  prix: this.editedItem.prix,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then(() => {})
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
          this.progress = false;
          this.editedItem.tier_type = tier_type;
          this.items.splice(this.item.index, 1, this.editedItem);
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_TIER_TYPE,
              variables: {
                tiertypeprix: {
                  prix: this.editedItem.prix,
                  tier_type_id: this.editedItem.tier_type_id,
                  produit_id: this.item.produit_id,
                  create_uid: this.$store.state.me.id,
                  write_uid: this.$store.state.me.id,
                },
              },
            })
            .then((data) => {
              this.editedItem.id = data.data.createTierTypePrix.id;
              this.editedItem.tier_type = tier_type;
              this.items.push(this.editedItem);
              this.progress = false;
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_color = "error";
              this.snackbar_text = error.message;
              this.snackbar = true;
            });
        }
        this.close();
      }
    },
  },
};
</script>
